import { useEffect, useState } from "react"
import { Event, track } from "../analytics"

export const useThemeSwitcher = () => {
  const [theme, setTheme] = useState("dark")

  const setMode = mode => {
    window.localStorage.setItem("theme", mode)
    setTheme(mode)
  }

  const toggleTheme = () => {
    track(Event.theme_toggled, { theme: theme === "light" ? "dark" : "light" })
    const identify = new window.amplitude.Identify().append(
      "currentTheme",
      theme === "light" ? "dark" : "light"
    )
    window.amplitude.getInstance().identify(identify)

    theme === "light" ? setMode("dark") : setMode("light")
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme")
    localTheme && setTheme(localTheme)
    const identify = new window.amplitude.Identify().append(
      "currentTheme",
      localTheme ? localTheme : "dark"
    )
    window.amplitude.getInstance().identify(identify)
  }, [])
  return [theme, toggleTheme]
}

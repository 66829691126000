import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Lato !important;
  }
  body{
    background-color: ${props => props.theme.colors.background.primary[500]};
    transition: all 500ms ease-in-out;
    @media only screen and (max-width: 600px) {
    margin: 0px
  }
  }
  &.twitter-tweet{
      font-size:${props => props.theme.fonts.size.small};
      border-radius: 4px;
      color: ${props => props.theme.colors.accent.primary[400]};
      max-width:350px;
      @media only screen and (max-width: 600px) {
        max-width:90vw;
    }
      div {
        font-weight: normal;
        color: ${props => props.theme.colors.text.primary[500]};
        font-size: ${props => props.theme.fonts.size.small};
      }
      a {
        font-size: ${props => props.theme.fonts.size.small};
        color: ${props => props.theme.colors.text.primary[500]};
      }
  }
 
`

export default GlobalStyle

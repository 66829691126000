import React from "react"
import Text from "components/common/Text"
import Space from "components/common/Space"
import { useTheme } from "styled-components"

const ShortInfo = ({ children }) => {
  const theme = useTheme()
  return (
    <Space margin="1rem 0px 4rem 0px">
      <Text size="medium" weight="light" color={theme.colors.text.primary[500]}>
        {children}
      </Text>
    </Space>
  )
}

export default ShortInfo

import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({
  title = "Chaitanya Deorukhkar",
  description = "Chaitanya Deorukhkar - Portfolio",
  path,
  image = "preview.png",
  type = "website",
}) => {
  const creator = "@_anothercoder"
  const siteUrl = "https://chaitanyadeorukhkar.com"
  const url = path ? `${siteUrl}/${path}` : siteUrl
  const imageURL = `${siteUrl}/${image}`

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="title" content={title} />
      <meta property="description" content={description} />
      <meta property="og:image" content={imageURL} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta name="twitter:creator" content={creator} />
      <meta property="twitter:image" content={imageURL} />
    </Helmet>
  )
}

export default SEO

import React from "react"
import styled, { useTheme } from "styled-components"
import { OutboundLink } from "gatsby-plugin-amplitude-analytics"

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const ExternalLink = styled(OutboundLink)`
  text-decoration: none;
  margin-left: 1.5rem;
  :first-of-type {
    margin-left: 0px;
  }
  &:hover {
    cursor: pointer;
    path {
      transition: fill 200ms;
      fill: ${props => props.theme.colors.accent.primary[500]};
    }
  }
`
const SocialLinks = ({ size }) => {
  const theme = useTheme()

  return (
    <Container>
      <ExternalLink
        href="https://twitter.com/_anothercoder"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        <svg width={size} viewBox="0 0 512 512">
          <path
            d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
            fill={theme.colors.accent.primary[200]}
          />
        </svg>
      </ExternalLink>
      <ExternalLink
        href="https://www.linkedin.com/in/chaitanyadeorukhkar/"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        <svg
          viewBox="0 0 512 512"
          width={size}
          height={size}
          fill={theme.colors.accent.primary[200]}
        >
          <path d="M475.074 0H36.926C16.53 0 0 16.531 0 36.926v438.148C0 495.47 16.531 512 36.926 512h438.148C495.47 512 512 495.469 512 475.074V36.926C512 16.53 495.469 0 475.074 0zM181.61 387h-62.347V199.426h62.347zm-31.172-213.188h-.406c-20.922 0-34.453-14.402-34.453-32.402 0-18.406 13.945-32.41 35.274-32.41 21.328 0 34.453 14.004 34.859 32.41 0 18-13.531 32.403-35.274 32.403zM406.423 387h-62.34V286.652c0-25.218-9.027-42.418-31.586-42.418-17.223 0-27.48 11.602-31.988 22.801-1.649 4.008-2.051 9.61-2.051 15.215V387h-62.344s.817-169.977 0-187.574h62.344v26.558c8.285-12.78 23.11-30.96 56.188-30.96 41.02 0 71.777 26.808 71.777 84.421zm0 0" />
        </svg>
      </ExternalLink>
      <ExternalLink
        href="https://www.github.com/chaitanyadeorukhkar/"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        <svg height={size} viewBox="0 0 24 24" width={size}>
          <path
            d="M12 .5C5.37.5 0 5.78 0 12.292c0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56C20.565 21.917 24 17.495 24 12.292 24 5.78 18.627.5 12 .5z"
            fill={theme.colors.accent.primary[200]}
          />
        </svg>
      </ExternalLink>
    </Container>
  )
}

export default SocialLinks

import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"

const ContentWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0px;
`

const Content = styled.div`
  padding: 0px 1rem;
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`

const Wrapper = ({ children }) => {
  return (
    <ContentWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <Content>{children}</Content>
    </ContentWrapper>
  )
}

export default Wrapper

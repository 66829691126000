import React from "react"
import YouTube from "components/talks/YouTube"
import Title from "components/talks/Title"
import ShortInfo from "components/talks/ShortInfo"
import MDXContentWrapper from "components/common/MDXContentWrapper"
import MiniAvatar from "components/common/MiniAvatar"

const TalkContent = ({ youtubeUrl, title, shortInfo, children }) => {
  return (
    <React.Fragment>
      <YouTube url={youtubeUrl} />
      <MDXContentWrapper>
        <Title>{title}</Title>
        <MiniAvatar from="Talk" title={title} />
        <ShortInfo>{shortInfo}</ShortInfo>
        {children}
      </MDXContentWrapper>
    </React.Fragment>
  )
}

export default TalkContent

import React from "react"
import styled from "styled-components"

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  * {
    margin-right: 8px;
    width: 400px;
    @media only screen and (max-width: 600px) {
      margin-right: 0px;
      width: auto;
    }
  }
`

const Wrapper = ({ children }) => {
  return <ContentWrapper>{children}</ContentWrapper>
}

export default Wrapper

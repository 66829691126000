import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"
import Text from "components/common/Text"
import { motion } from "framer-motion"
import { Event, track } from "../../analytics"

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 5vw;
  transition: margin 200ms;
  margin-left: ${props => (props.showBackButton ? `0.5rem` : "0px")};
  padding-left: ${props => (props.showBackButton ? `0px` : "5vw")};
`

const BlurredContainer = styled.div`
  position: fixed;
  top: 0;
  transition: background-color 500ms ease-in-out, box-shadow 300ms ease-in-out;
  box-shadow: ${props =>
    props.showShadow ? `0px 0px 25px 0px rgba(0, 0, 0, 0.2)` : "none"};
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.background.primary[400]};
  z-index: 999;
  backdrop-filter: blur(10px);
`

const ToggleButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  display: flex;
  transition: transform 500ms;
  margin-top: 2px;
  margin-left: 10px;
  z-index: 2;
  margin-right: -20px;
  &:hover {
    transform: scale(1.09);
    cursor: pointer;
  }
  &:focus {
    transform: scale(1.09);
  }
`

const LinkNav = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 200ms;
  padding-right: 8px;
  padding-left: 8px;
  text-decoration: none;
  font-size: ${props => props.theme.fonts.size["medium"]};
  color: ${props => props.theme.colors.text.secondary[500]};
  letter-spacing: 2px;
  ${props =>
    props.first &&
    `
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  `};
  ${props =>
    props.last &&
    `
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  `};
  &:hover {
    background-color: ${props => props.theme.colors.accent.primary[50]};
  }
  &:focus {
    background-color: ${props => props.theme.colors.accent.primary[50]};
    outline: none;
  }
  &.current {
    background-color: ${props => props.theme.colors.accent.primary[100]};
  }
`

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 4px;
  z-index: 1;
`

const BackContent = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  text-decoration: none;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  margin-left: 2px;
  transition: background-color 200ms;
  &:hover {
    background-color: ${props => props.theme.colors.accent.primary[50]};
  }
  &:focus {
    background-color: ${props => props.theme.colors.accent.primary[50]};
    outline: none;
  }
`

const LinkBack = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 22px;
  font-size: ${props => props.theme.fonts.size["medium"]};
  color: ${props => props.theme.colors.text.secondary[500]};
  letter-spacing: 2px;
`
const goBack = ({ backTo }) => {
  track(Event.back_clicked, { to: backTo ? backTo : "unknown" })
  if (backTo) {
    navigate(`/${backTo}`)
  } else {
    window.history.back()
  }
}

const HeaderContainer = styled.header`
  height: 80px;
  width: 100%;
`

const Header = ({ toggleTheme, currentTheme, showBackButton, backTo }) => {
  const theme = useTheme()
  const [showShadow, setShowShadow] = useState(false)
  const handleScroll = () => {
    const position = window.pageYOffset
    if (position > 100 && !showShadow) setShowShadow(true)
    else if (position < 100) setShowShadow(false)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <HeaderContainer>
      <BlurredContainer showShadow={showShadow}>
        <StyledHeader showBackButton={showBackButton}>
          {showBackButton ? (
            <BackContainer>
              <BackContent onClick={() => goBack({ backTo })}>
                <LinkBack>
                  <svg
                    width="20px"
                    height="20px"
                    fill={theme.colors.text.secondary[500]}
                    viewBox="0 0 492 492"
                  >
                    <path d="M198.608 246.104L382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z" />
                  </svg>
                </LinkBack>
                <Text size="small" color={theme.colors.accent.primary[500]}>
                  {backTo.charAt(0).toUpperCase() +
                    backTo.substr(1).toLowerCase()}
                </Text>
              </BackContent>
            </BackContainer>
          ) : (
            <React.Fragment>
              <LinkNav
                onClick={() =>
                  track(Event.navigation_tab_clicked, { tab: "Home" })
                }
                to="/"
                activeClassName="current"
                first={"true"}
              >
                {"Home"}
              </LinkNav>
              <LinkNav
                onClick={() =>
                  track(Event.navigation_tab_clicked, { tab: "Talks" })
                }
                to="/talks"
                activeClassName="current"
                last={"true"}
              >
                {"Talks"}
              </LinkNav>
            </React.Fragment>
          )}
          <ToggleButton aria-label="toggle-theme" onClick={() => toggleTheme()}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              key={currentTheme}
            >
              {currentTheme === "dark" ? (
                <svg viewBox="0 0 512 512" width={30} height={30}>
                  <path
                    d="M300.379 18.219l-8.853-8.853A32.234 32.234 0 00268.912 0H243.91a32.24 32.24 0 00-22.635 9.365l-8.832 8.853a32.233 32.233 0 00-9.365 22.613v65.835h106.667V40.832a32.238 32.238 0 00-9.366-22.613z"
                    fill="##000"
                  />

                  <path
                    d="M346.48 170.219a6.397 6.397 0 01-2.432-3.797c-4.267-22.165-15.701-81.067-51.072-81.067h-73.131c-35.456 0-46.805 59.029-51.2 81.28a6.4 6.4 0 01-2.411 3.627c-56.031 45.01-64.966 126.92-19.956 182.951a130.114 130.114 0 0010.932 11.993 139.968 139.968 0 0099.2 40.128h2.304a141.208 141.208 0 0099.691-44.032 129.881 129.881 0 0036.672-94.635 132.26 132.26 0 00-48.597-96.448z"
                    fill={"#fff"}
                  />
                </svg>
              ) : (
                <svg viewBox="0 0 512 512" width={30} height={30}>
                  <path
                    d="M300.379 18.219l-8.853-8.853A32.234 32.234 0 00268.912 0H243.91a32.24 32.24 0 00-22.635 9.365l-8.832 8.853a32.233 32.233 0 00-9.365 22.613v65.835h106.667V40.832a32.238 32.238 0 00-9.366-22.613z"
                    fill="#000"
                  />
                  <g fill="#ffc107">
                    <path d="M256.411 426.667c-5.891 0-10.667 4.776-10.667 10.667v64c0 5.891 4.776 10.667 10.667 10.667s10.667-4.776 10.667-10.667v-64c0-5.892-4.776-10.667-10.667-10.667zM176.411 405.333c-5.102-2.945-11.625-1.198-14.571 3.904l-32 55.467c-3.16 4.972-1.692 11.564 3.279 14.724s11.564 1.692 14.724-3.279c.161-.253.311-.513.45-.778l32-55.467c2.95-5.096 1.212-11.618-3.882-14.571zM103.28 342.763l-55.467 32c-5.22 2.731-7.238 9.176-4.507 14.396s9.176 7.238 14.396 4.507c.266-.139.525-.289.778-.45l55.467-32c4.972-3.16 6.44-9.753 3.279-14.724a10.665 10.665 0 00-13.946-3.729zM350.982 409.131c-3.16-4.972-9.753-6.44-14.724-3.279a10.667 10.667 0 00-3.729 13.946l32 55.467c2.731 5.22 9.176 7.238 14.396 4.507 5.22-2.731 7.238-9.176 4.507-14.396a10.542 10.542 0 00-.45-.778l-32-55.467zM464.966 374.763l-55.467-32c-4.971-3.16-11.564-1.692-14.724 3.279-3.16 4.972-1.692 11.564 3.279 14.724.253.161.513.311.778.45l55.467 32a10.667 10.667 0 0010.667-18.453z" />
                  </g>
                  <path
                    d="M346.48 170.219a6.397 6.397 0 01-2.432-3.797c-4.267-22.165-15.701-81.067-51.072-81.067h-73.131c-35.456 0-46.805 59.029-51.2 81.28a6.4 6.4 0 01-2.411 3.627c-56.031 45.01-64.966 126.92-19.956 182.951a130.114 130.114 0 0010.932 11.993 139.968 139.968 0 0099.2 40.128h2.304a141.208 141.208 0 0099.691-44.032 129.881 129.881 0 0036.672-94.635 132.26 132.26 0 00-48.597-96.448z"
                    fill={"#ffc107"}
                  />
                </svg>
              )}
            </motion.div>
          </ToggleButton>
        </StyledHeader>
      </BlurredContainer>
    </HeaderContainer>
  )
}

export default Header

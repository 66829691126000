import React from "react"
import Text from "components/common/Text"
import { useTheme } from "styled-components"

const Title = ({ children }) => {
  const theme = useTheme()
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Text
        size="xlarge"
        weight="bold"
        lineHeight="medium"
        color={theme.colors.text.primary[500]}
      >
        {children}
      </Text>
    </div>
  )
}

export default Title

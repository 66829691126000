import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"

const Container = styled(motion.div)`
  overflow: hidden;
  width: "100%";
  height: 60vh;
  border-radius: 2px;
  @media only screen and (max-width: 600px) {
    height: 40vh;
    min-height: 300px;
    border-radius: 0px;
  }
`

const getTimeValueInSeconds = timeValue => {
  if (Number(timeValue).toString() === timeValue) {
    return timeValue
  }

  const {
    2: hours = "0",
    4: minutes = "0",
    6: seconds = "0",
  } = timeValue.match(/((\d*)h)?((\d*)m)?((\d*)s)?/)

  return String((Number(hours) * 60 + Number(minutes)) * 60 + Number(seconds))
}

const getYouTubeIFrameSrc = urlString => {
  const url = new URL(urlString)
  let id = url.searchParams.get("v")
  if (url.host === "youtu.be") {
    id = url.pathname.slice(1)
  }
  const embedUrl = new URL(`https://www.youtube-nocookie.com/embed/${id}?rel=0`)
  url.searchParams.forEach((value, name) => {
    if (name === "v") {
      return
    }

    if (name === "t") {
      embedUrl.searchParams.append("start", getTimeValueInSeconds(value))
    } else {
      embedUrl.searchParams.append(name, value)
    }
  })

  return embedUrl.toString()
}

const YouTube = ({ url }) => {
  return (
    <Container
      initial={{ opacity: 0, boxShadow: "none" }}
      animate={{
        opacity: 1,
        boxShadow: "-4px 10px 30px -5px rgba(0, 0, 0, 0.3)",
      }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <iframe
        width="100%"
        height="100%"
        src={getYouTubeIFrameSrc(url)}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="youtube"
      ></iframe>
    </Container>
  )
}

export default YouTube
